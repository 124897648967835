import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IOrder } from '@model/interfaces/order';
import { ICreateOrderPayload } from '@model/interfaces/custom/create-order-payload';
import { Observable } from 'rxjs';
import { IUpdateOrderPayload } from '@model/interfaces/custom/update-order-payload';
import { IOrderPallet } from '@model/interfaces/order-pallet';
import { IOrderMachinePart } from '@model/interfaces/order-machine-part';

export const emptyOrder: IOrder = {
    ContactEmail: null,
    ContactName: null,
    ContactPhoneNumber: null,
    CreatedByAdmin: null,
    CreatedByCustomer: null,
    CustomerId: 0,
    DateCreated: null,
    DateModified: null,
    DeliverToAddress: null,
    DeliverToCity: null,
    DeliverToCompany: null,
    DeliverToState: null,
    DeliverToZip: null,
    DeliveryASAP: null,
    DeliveryEndDate: null,
    DeliveryStartDate: null,
    Id: 0,
    InventoryTypeId: 0,
    ModifiedByAdmin: null,
    ModifiedByCustomer: null,
    OrderNumber: null,
    Prepped: null,
    ReferenceNumber: null,
    SpecialInstructions: null,
    OrderStatusId: null,
};

@Injectable({
    providedIn: 'root',
})
export class OrderService extends BaseService<IOrder> {
    constructor(public http: HttpClient) {
        super('/orders', http, null, { entityName: 'Orders' });
    }

    formatTitleText(order: IOrder): void {
        this.setTitle(`Order: ${order.OrderNumber}`);
    }

    getEmptyOrder(): IOrder {
        return { ...emptyOrder };
    }

    createOrder(payload: ICreateOrderPayload): Observable<IOrder> {
        return this.http.post<IOrder>(`/orders/create`, payload);
    }

    updateOrder(id: number, payload: IUpdateOrderPayload): Observable<IOrder> {
        return this.http.put<IOrder>(`/orders/update/${id}`, payload);
    }

    updateOrderShippingDetails(id: number, orderPallets: IOrderPallet[]): Observable<void> {
        return this.http.put<void>(`/orders/update-shipping-details/${id}`, orderPallets);
    }

    voidOrder(id: number): Observable<void> {
        return this.http.post<void>(`/orders/void/${id}`, {});
    }

    getBarCodePdf(orderId: number, documentId: number): Observable<string> {
        return this.http.get<string>(`/orders/${orderId}/documents/${documentId}`, {});
    }

    scanOrder(orderId: number): Observable<void> {
        return this.http.put<void>(`/orders/scan/${orderId}`, {});
    }

    getOrderMachinePartsByCustomerId(customerId: number): Observable<IOrderMachinePart[]> {
        return this.http.get<IOrderMachinePart[]>(`/order/order-machines/order-machine-parts/get-all-parts/${customerId}`);
    }
}
