import { DatePipe } from '@angular/common';
import { IInventory } from '@model/interfaces/inventory';
import { EntityListColumn, EntityListConfig, IEntityListConfig, SelectTypes } from '@mt-ng2/entity-list-module';

export class ViewAllUsedInventoryEntityListConfig extends EntityListConfig {
    constructor(datePipe: DatePipe) {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Customer', 'Name'],
                    name: 'Customer',
                    columnClass: 'column-left-aligned',
                }),
                new EntityListColumn({
                    accessors: ['DateReceived'],
                    name: 'Date Received',
                    pipes: ['date:medium'],
                    columnClass: 'column-left-aligned',
                }),
                new EntityListColumn({
                    accessors: ['FMDNumber'],
                    name: 'FMD Number',
                    columnClass: 'column-left-aligned',
                }),
                new EntityListColumn({
                    accessors: ['Location'],
                    name: 'Location',
                    columnClass: 'column-left-aligned',
                }),
                new EntityListColumn({
                    accessorFunction: (inventory: IInventory): string => {
                        if (inventory.PaidThroughMonthly) return 'Monthly';

                        if (inventory.PaidThroughDate) return `${datePipe.transform(inventory.PaidThroughDate, 'medium')}`;

                        return 'N/A';
                    },
                    name: 'Paid Through Date',
                    sort: {
                        sortProperty: 'PaidThroughDate',
                    },
                }),
                new EntityListColumn({
                    accessors: ['Company'],
                    name: 'Company',
                    columnClass: 'column-left-aligned',
                }),
                new EntityListColumn({
                    accessors: ['Model', 'Manufacturer', 'Name'],
                    name: 'Make',
                    columnClass: 'column-left-aligned',
                }),
                new EntityListColumn({
                    accessors: ['Model', 'Name'],
                    name: 'Model No.',
                    columnClass: 'column-left-aligned',
                    sort: {
                        sortProperty: 'Model.Name',
                    },
                }),
                new EntityListColumn({
                    accessors: ['SerialNumber'],
                    name: 'Serial No.',
                    columnClass: 'column-left-aligned',
                }),
                new EntityListColumn({
                    accessors: ['PieceCount'],
                    name: 'Piece Count',
                }),
                new EntityListColumn({
                    accessors: ['InventoryStatus', 'Name'],
                    name: 'Status',
                }),
            ],
            select: {
                type: SelectTypes.Multi,
            },
        };

        super(listConfig);
    }
}
