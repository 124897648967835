import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService, LocalizeDatesByName } from '@mt-ng2/base-service';

import { InventoryTypes } from '@model/enums/inventory-types.enum';
import { IQuantityOfModelsExport } from '@model/interfaces/custom/quantity-of-models-export';
import { IInventory } from '@model/interfaces/inventory';
import { catchError, map, Observable } from 'rxjs';
import { IInventoryAndQuantity } from '@model/interfaces/custom/inventory-and-quantity';
import { SearchParams } from '@mt-ng2/common-classes';

export const emptyInventory: IInventory = {
    DateCreated: null,
    Id: 0,
    InventoryStatusId: 0,
    InventoryTypeId: 0,
    ModelId: 0,
    SerialNumber: null,
    PaidThroughMonthly: false,
    Approved: false,
    Archived: false,
    AssociatedPurchaseOrderId: null,
};

@Injectable({
    providedIn: 'root',
})
export class InventoryService extends BaseService<IInventory> {
    constructor(public http: HttpClient) {
        super('/inventories', http);
    }

    getEmptyInventory(): IInventory {
        return { ...emptyInventory };
    }

    updateCompany(inventory: IInventory): Observable<void> {
        return this.http.put<void>(`/inventories/update-company`, { Id: inventory.Id, Company: inventory.Company });
    }

    updateLocation(inventory: IInventory): Observable<void> {
        return this.http.put<void>(`/inventories/update-location`, { Id: inventory.Id, Location: inventory.Location });
    }

    updatePieceCount(inventory: IInventory): Observable<void> {
        return this.http.put<void>(`/inventories/update-piece-count`, { Id: inventory.Id, PieceCount: inventory.PieceCount });
    }

    updateFmdNumber(inventory: IInventory): Observable<void> {
        return this.http.put<void>(`/inventories/update-fmd-number`, { Id: inventory.Id, FmdNumber: inventory.FMDNumber });
    }

    updateSerialNumber(inventory: IInventory): Observable<void> {
        return this.http.put<void>(`/inventories/update-serial-number`, { Id: inventory.Id, SerialNumber: inventory.SerialNumber });
    }

    updateModelNumber(inventory: IInventory): Observable<void> {
        return this.http.put<void>(`/inventories/update-model-number`, { Id: inventory.Model.Id, ModelNumber: inventory.Model.Name });
    }

    updateArchived(inventory: IInventory): Observable<any> {
        return this.http.put<void>(`/inventories/update-archived/${inventory.Id}`, {});
    }

    getQuantityOfModels(customerIds: number[]): Observable<IQuantityOfModelsExport[]> {
        const customersString = customerIds && customerIds.length ? '&customerIds=' + customerIds.join(',') : '';
        return this.http.get<IQuantityOfModelsExport[]>(
            `/inventories/get-quantity-of-models?inventoryTypeId=${InventoryTypes.Used}${customersString}`,
        );
    }

    approve(inventoryIds: number[]): Observable<void> {
        return this.http.put<void>(`/inventories/approve`, inventoryIds);
    }

    getBarCodePdf(id: number): Observable<string> {
        return this.http.get<string>(`/inventories/${id}/generate-barcode-pdf`, {});
    }

    consolidatedLineItemsGet(searchparameters: SearchParams): Observable<HttpResponse<IInventoryAndQuantity[]>> {
        const params = this.getHttpParams(searchparameters);
        const result = this.http
            .get<IInventoryAndQuantity[]>('/inventories/consolidated-line-items-search', {
                observe: 'response',
                params: params,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
        return this.datesToLocalize?.length
            ? result.pipe(
                  map((resp) => {
                      resp.body?.forEach((x) => {
                          LocalizeDatesByName(x, this.datesToLocalize);
                      });
                      return resp;
                  }),
              )
            : result;
    }
}
