import { IInventoryModelWithQuantity } from '@model/interfaces/custom/inventory-model-with-quantity';
import { IInventoryModel } from '@model/interfaces/inventory-model';
import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection, SelectTypes } from '@mt-ng2/entity-list-module';

export class InventoryModelsEntityListConfig extends EntityListConfig {
    constructor(customerId: number) {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Name'],
                    name: 'Model Number',
                    columnClass: 'column-left-aligned',
                }),
                new EntityListColumn({
                    accessors: ['ItemCode'],
                    name: 'Item Code',
                    columnClass: 'column-left-aligned',
                }),
                new EntityListColumn({
                    name: 'Quantity',
                    columnClass: 'column-left-aligned',
                    sort: {
                        sortProperty: 'Inventories.Count',
                    },
                    accessorFunction: (entity: IInventoryModelWithQuantity) => {
                        return `${entity.NameWithQuantity}`;
                    }
                }),
                new EntityListColumn({
                    accessors: ['Manufacturer', 'Name'],
                    name: 'Make',
                    columnClass: 'column-left-aligned',
                }),
                new EntityListColumn({
                    accessors: ['Category', 'Name'],
                    name: 'Category',
                    columnClass: 'column-left-aligned',
                }),
            ],
            select: {
                type: SelectTypes.Multi,
            },
        };
        super(listConfig);
    }
}
