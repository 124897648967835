import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { IColumnSortedEvent, SortDirection, ISelectionChangedEvent } from '@mt-ng2/entity-list-module';
import { InventoryModelService } from '../../admin-portal/inventory-models/services/inventory-model.service';
import { CategoryService } from '../../admin-portal/categories/category.service';
import { IInventoryModel } from '@model/interfaces/inventory-model';
import { entityListModuleConfig } from '@common/shared.module';
import { InventoryModelsEntityListConfig } from './view-all-inventory.entity-list-config';
import { DynamicSearchFilterTypes, DynamicSearchValues, IDynamicSearchFilters, IMetaItem } from '@mt-ng2/dynamic-search-filters';
import { IInventorySelection } from '@model/interfaces/custom/inventory-selection';
import { ICustomer } from '@model/interfaces/customer';
import { ManufacturerService } from 'admin-portal/new-inventories/services/manufacturer.service';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IOrder } from '@model/interfaces/order';
import { IOrderMachine } from '@model/interfaces/order-machine';
import { IInventoryModelWithQuantity } from '@model/interfaces/custom/inventory-model-with-quantity';
import { InventoryStatuses } from '@model/enums/inventory-statuses.enum';
import { OrderService } from 'admin-portal/orders/services/order.service';
import { IOrderMachinePart } from '@model/interfaces/order-machine-part';

@Component({
    selector: 'app-view-all-inventory',
    templateUrl: './view-all-inventory.component.html',
})
export class ViewAllInventoryComponent implements OnInit {
    @Input() selectedCustomer: ICustomer;
    @Output('selectionsMade') selectionsMade = new EventEmitter<IInventorySelection[]>();
    @Input() createdOrder: IOrder; 
    @Input() orderMachines: IOrderMachine[] = [];
    
    modalSelection: IInventorySelection[];
    inventoryModels: IInventoryModel[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    dynamicSearchFiltersConfig: IDynamicSearchFilters = [];
    total: number;
    entityListConfig: InventoryModelsEntityListConfig;
    query = '';
    selectedCategoryIds: number[] = [];
    order: string;
    orderDirection: string;
    selectedManufacturerIds: number[] = [];
    selectedInventoryModels: IInventoryModel[] = [];
    orderMachineParts: IOrderMachinePart[] = [];


    constructor(
        private inventoryModelService: InventoryModelService, 
        private categoryService: CategoryService,
        private manufacturerService: ManufacturerService,
        private ordersService: OrderService,

    ) {}

    ngOnInit(): void {
        this.entityListConfig = new InventoryModelsEntityListConfig(this.selectedCustomer.Id);
        this.order = this.entityListConfig.getDefaultSortProperty();
        this.orderDirection = this.entityListConfig.getDefaultSortDirection();

        forkJoin([
            this.categoryService.getSearchFilterItems(),
            this.manufacturerService.getAll()
        ]).subscribe(([categories, manufacturers]) => {
            const manufacturerSelectOptions = manufacturers.map((inventoryModel) => {
                return new MtSearchFilterItem(inventoryModel, false);
            });
                    
            // Add N/A option to manufacturer select
            manufacturerSelectOptions.unshift(new MtSearchFilterItem({ Id: 0, Name: 'N/A', Sort: 0 }, false));

            this.dynamicSearchFiltersConfig = [
                {
                    Searchbar: {
                        label: 'Search',
                        type: DynamicSearchFilterTypes.Searchbar,
                    },
                    Manufacturers: {
                        label: 'Filter by Make',
                        type: DynamicSearchFilterTypes.Select,
                        options: {
                            selectOptions : manufacturerSelectOptions
                        },
                    },
                    Categories: {
                        label: 'Filter by Category',
                        type: DynamicSearchFilterTypes.Select,
                        options: {
                            selectOptions: categories,
                        },
                    },
                },
            ];
        });
        this.ordersService.getOrderMachinePartsByCustomerId(this.selectedCustomer?.Id).subscribe((orderMachineParts) => {
            this.orderMachineParts = orderMachineParts;
            this.getInventoryModels();
        });
    }

    search(evt: DynamicSearchValues): void {
        this.currentPage = 1;
        this.query = evt.Searchbar as string;
        if (evt.Manufacturers) {
            this.selectedManufacturerIds = (evt.Manufacturers as IMetaItem[]).map((inventoryModel) => inventoryModel.Id);
        }
        if (evt.Categories) {
            this.selectedCategoryIds = (evt.Categories as IMetaItem[]).map((inventoryModel) => inventoryModel.Id);
        }
        this.getInventoryModels();
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ManufacturerIds',
                valueArray: this.selectedManufacturerIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CategoryIds',
                valueArray: this.selectedCategoryIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CustomerIds',
                valueArray: [this.selectedCustomer.Id],
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'IncludeModelQuantities',
                value: 'true',
            }),
        );

        return _extraSearchParams;
    }

    getInventoryModelsCall(): Observable<HttpResponse<IInventoryModel[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.inventoryModelService.get(searchparams);
    }

    getInventoryModels(): void {
        this.getInventoryModelsCall().subscribe((answer) => {
            this.inventoryModels = answer.body.map((inventoryModel: IInventoryModelWithQuantity) => {
                let countSubtract = 0;
                this.orderMachineParts.forEach((orderMachine) => {
                    if(inventoryModel.Id === orderMachine.InventoryModelId)
                        countSubtract++;
                });
                if (countSubtract < 0) {
                    countSubtract = 0;
                }
                inventoryModel.Quantity = inventoryModel.Inventories?.filter((x) =>x.CustomerId === this.selectedCustomer.Id && x.InventoryStatusId === InventoryStatuses.ReceivedIn && x.AssociatedPurchaseOrderId === null)?.length;
                if (this.selectedInventoryModels.findIndex((selectInventory) => selectInventory.Id === inventoryModel.Id) >= 0)
                    inventoryModel.NameWithQuantity = `${inventoryModel.Quantity - 1 - countSubtract < 0 ? 0 :  inventoryModel.Quantity - 1 - countSubtract}`;
                else 
                    inventoryModel.NameWithQuantity = `${inventoryModel.Quantity - countSubtract < 0 ? 0 :  inventoryModel.Quantity - countSubtract}`;
                if (inventoryModel.Quantity < 0 ) {
                    inventoryModel.Quantity = 0;
                }
                inventoryModel.CurrentOnOrder = countSubtract;
                inventoryModel.IsOver = (inventoryModel.Quantity - countSubtract <= 0);
                return inventoryModel;
            });
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getInventoryModels();
    }

    selectionChanged(event: ISelectionChangedEvent): void {
        this.selectedInventoryModels = event.selectedEntities as IInventoryModelWithQuantity[];        
        this.inventoryModels = this.inventoryModels.map((inventoryModel: IInventoryModelWithQuantity) => {
            let countSubtract = 0;
            this.orderMachineParts.forEach((orderMachine) => {
                if(inventoryModel.Id === orderMachine.InventoryModelId)
                    countSubtract++;
            });
            if (countSubtract <= 0) countSubtract = 0; 
            inventoryModel.Quantity = inventoryModel.Inventories?.filter((inventoryModel) =>inventoryModel.CustomerId === this.selectedCustomer.Id && inventoryModel.InventoryStatusId === InventoryStatuses.ReceivedIn && inventoryModel.AssociatedPurchaseOrderId === null)?.length;
            if (this.selectedInventoryModels.findIndex((orderMachine) => orderMachine.Id === inventoryModel.Id) >= 0)
                inventoryModel.NameWithQuantity = `${inventoryModel.Quantity - 1 - countSubtract < 0 ? 0 :  inventoryModel.Quantity - 1 - countSubtract}`;
            else 
                inventoryModel.NameWithQuantity = `${inventoryModel.Quantity - countSubtract < 0 ? 0 :  inventoryModel.Quantity - countSubtract}`;

            if (inventoryModel.Quantity < 0 ) {
                inventoryModel.Quantity = 0;
            } 
            inventoryModel.CurrentOnOrder = countSubtract;               
            if (inventoryModel.Quantity - countSubtract <= 0)
                inventoryModel.IsOver = true;
            else    
                inventoryModel.IsOver = false;
           return inventoryModel; 
        });
        this.modalSelection = this.selectedInventoryModels.map((im) => {
            return {
                Inventory: null,
                InventoryModel: im,
            };
        });
    }

    confirmSelections(): void {
        this.selectionsMade.emit(this.modalSelection);
    }
}
